import axios from "axios";
import fetchWithHeaders from "../FetchIntercepter";
import {
  API_URL_SIGN_IN,
  BASE_URL,
  ERROR_MESSAGES,
  GET_USERS,
  ORDER_USER,
  RENEW_TOKEN,
  USER_FORGOT_PASSWORD,
  USER_MANAGEMENT_URL,
  USER_RESET_PASSWORD,
  USER_SIGN_IN,
  PAYMENT_INTENT,
  CREATE_PAYMENT,
  CREATE_INVOICE,
  ORDER_ADMIN,
  SAVE_FOR_REVIEW,
  UPLOAD_DOCUMENT,
  BILLING_DATA,
  APPROVE_ORDER,
  REVISE_ORDER,
  UPLOAD_IMAGE,
  USER_PROFILE,
  ORDER_BASE_URL,
  USER_BILLING_DATA,
  SIGN_UP,
  ATTACH_PAYMENTS,
  START_PAYMENTS,
  ALL_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  SAVE_PAYMENTS,
  UPDATE_PAYMENTS,
  CANCEL_URL,
  SUCCESS_URL,
} from "./APIUrls";
import { CONSTANTS } from "./Constants";
import { successToast } from "./useToast";

export const debounce = (child, delay) => {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      child.apply(this, args);
    }, delay);
  };
};

const constructUrl = ({
  baseAPI,
  limit,
  currentPage,
  search,
  sort,
  filter,
}) => {
  const baseUrl = `${baseAPI}?limit=${limit}&page=${currentPage}`;
  let url = baseUrl;

  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }

  if (sort) {
    url += `&sort_order=${encodeURIComponent(sort)}`;
  }

  for (const key in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, key)) {
      const values = filter[key]
        .map((value) => `${key}[]=${encodeURIComponent(value)}`)
        .join("&");
      url += `&${values}`;
    }
  }

  return url;
};

export const formatFilter = (filter) => {
  const formattedFilter = {};
  for (const key in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, key)) {
      const formattedKey = key.toLowerCase().replace(/\s+/g, "_");
      formattedFilter[formattedKey] = filter[key];
    }
  }
  return formattedFilter;
};

export const signInUser = async (email, password, returnSecureToken) => {
  try {
    const response = await fetch(API_URL_SIGN_IN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        returnSecureToken: returnSecureToken,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const clientSignIn = async (email, password, returnSecureToken) => {
  try {
    const response = await fetch(USER_SIGN_IN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        returnSecureToken: returnSecureToken,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const clientSignUp = async (name, email, password) => {
  try {
    const response = await fetch(SIGN_UP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const userManagementLogin = async (email, name, payment_method) => {
  try {
    const response = await fetchWithHeaders(USER_MANAGEMENT_URL, {
      method: "POST",

      body: JSON.stringify({
        name: name,
        email: email,
        payment_method: payment_method,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      await response.json();
      throw new Error(ERROR_MESSAGES?.CONFLICT);
      // throw new Error(responseBody);
    }
  } catch (error) {
    console.log("user_error_catch: ", error);
    throw new Error(ERROR_MESSAGES.CONFLICT);
  }
};

export const ResetPasswordEmail = async (email) => {
  try {
    const response = await fetchWithHeaders(USER_FORGOT_PASSWORD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });

    if (response.ok) {
      successToast(CONSTANTS?.FORGOT_PASSWORD_RESET);
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const ResetPasswordRequestAPI = async (Password, OTP, email) => {
  try {
    const response = await fetch(USER_RESET_PASSWORD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        otp: OTP,
        password: Password,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const RenewToken = async (refreshToken) => {
  try {
    const response = await fetch(RENEW_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const fetchDeals = async ({
  limit,
  currentPage,
  search,
  sort,
  filter,
  setCount,
  setDealsData,
  setLoading,
}) => {
  try {
    setLoading(true);
    const baseAPI = GET_USERS;
    const url = constructUrl({
      baseAPI,
      limit,
      currentPage,
      search,
      sort,
      filter,
    });

    const response = await fetchWithHeaders(url, {
      method: "GET",
    });
    const result = await response.json();
    setCount(result?.totalCount);
    setDealsData(result?.data);
    debounce(setLoading(false), 300);
  } catch (error) {
    setLoading(false);
  }
};

export const DeleteData = async (rowID) => {
  try {
    const response = await fetchWithHeaders(`${BASE_URL}/${rowID}`, {
      method: "DELETE",

      body: JSON.stringify({}),
    });
    if (response.ok) {
      successToast(CONSTANTS?.USER_DELETED_TEXT);
      return await response.json();
    } else {
      console.log(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    console.log(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const OrderData = async ({
  limit,
  currentPage,
  search,
  filter,
  setCount,
  setOrderData,
  setLoading,
  setstats,
}) => {
  try {
    setLoading(true);
    const baseAPI = ORDER_USER;
    const url = constructUrl({
      baseAPI,
      limit,
      currentPage,
      search,
      filter,
    });

    const response = await fetchWithHeaders(url, {
      method: "GET",
    });
    const result = await response.json();
    setCount(result?.totalCount);
    setOrderData(result?.data);
    setstats(result?.stats);
    debounce(setLoading(false), 300);
  } catch (error) {
    setLoading(false);
  }
};

export const CreateNewOrder = async (data) => {
  try {
    const {
      page_type,
      price,
      business_name,
      website_url,
      existing_url,
      // ExistingUrl,
      content_requirements,
      content_examples,
      keywords,
      additional_notes,
    } = data;
    const response = await fetchWithHeaders(ORDER_BASE_URL, {
      method: "POST",
      body: JSON.stringify({
        page_type: page_type,
        business_name: business_name,
        website_url: website_url,
        existing_url: existing_url,
        // ExistingUrl: ExistingUrl,
        content_requirements: content_requirements,
        content_examples: content_examples,
        keywords: keywords,
        price: price,
        additional_notes: additional_notes,
      }),
    });
    if (response.ok) {

      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const stripePayment = async (data) => {
  try {
    const response = await fetchWithHeaders(PAYMENT_INTENT, {
      method: "POST",
      body: JSON.stringify({
        amount: Number(data),
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const createOrder = async (data) => {
  try {
    const response = await fetchWithHeaders(PAYMENT_INTENT, {
      method: "POST",
      body: JSON.stringify({
        amount: Number(data),
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const createPayment = async (stripe_payment_ref, order_id, amount) => {
  try {
    // const {
    const response = await fetchWithHeaders(CREATE_PAYMENT, {
      method: "POST",
      body: JSON.stringify({
        stripe_payment_ref: stripe_payment_ref,
        order_id: order_id,
        amount: amount,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const startPayment = async (id, amount) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetchWithHeaders(START_PAYMENTS, {
      method: "POST",
      body: JSON.stringify({
        payment_method_id: id,
        amount: amount*100,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const attachPayment = async (id) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetchWithHeaders(ATTACH_PAYMENTS, {
      method: "POST",
      body: JSON.stringify({
        payment_method_id: id,
      }),
      headers,
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const createInvoice = async (InvoiceLink, Amount, User, Date) => {
  try {
    const response = await fetchWithHeaders(CREATE_INVOICE, {
      method: "POST",
      body: JSON.stringify({
        qb_invoice: InvoiceLink,
        amount: Amount,
        user_id: User,
        qb_due_date: Date,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const adminOrderData = async ({
  limit,
  currentPage,
  search,
  filter,
  setDealsData,
  setLoading,
  setCount,
}) => {
  try {
    setLoading(true);

    const baseAPI = ORDER_ADMIN;
    const url = constructUrl({
      baseAPI,
      limit,
      currentPage,
      search,
      filter,
    });

    const response = await fetchWithHeaders(url, {
      method: "GET",
    });

    const result = await response.json();
    setCount(result?.totalCount);
    setDealsData(result?.data);
    debounce(setLoading(false), 200);
  } catch (error) {
    setLoading(false);
  }
};

export const sendForReview = async (comment, order_id, document_url) => {
  try {
    const response = await fetchWithHeaders(SAVE_FOR_REVIEW, {
      method: "POST",
      body: JSON.stringify({
        comment: comment,
        order_id: order_id,
        document_url: document_url,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const uploadDocument = async (formData, setFileUrl) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await axios.post(UPLOAD_DOCUMENT, formData, {
      headers,
    });

    if (!response) {
      throw new Error("Failed to upload file");
    }

    setFileUrl(response?.data?.url);
    return response;
  } catch (error) {
    console.log("errr: ", error);
  }
};

export const billingData = async ({
  limit,
  currentPage,
  search,
  filter,
  setBilling,
  setLoading,
  setCount,
}) => {
  try {
    setLoading(true);

    const baseAPI = BILLING_DATA;
    const url = constructUrl({
      baseAPI,
      limit,
      currentPage,
      search,
      filter,
    });

    const response = await fetchWithHeaders(url, {
      method: "GET",
    });

    const result = await response.json();
    setCount(result?.totalCount);
    setBilling(result?.data);
    // setstats(result?.stats);
    debounce(setLoading(false), 200);
  } catch (error) {
    console.error("Error fetching data for deals:", error);
    setLoading(false);
  }
};

export const userBillingData = async ({
  limit,
  currentPage,
  search,
  filter,
  setBilling,
  setLoading,
  setCount,
}) => {
  try {
    setLoading(true);

    const baseAPI = USER_BILLING_DATA;
    const url = constructUrl({
      baseAPI,
      limit,
      currentPage,
      search,
      filter,
    });

    const response = await fetchWithHeaders(url, {
      method: "GET",
    });

    const result = await response.json();
    setCount(result?.totalCount);
    setBilling(result?.data);
    debounce(setLoading(false), 200);
  } catch (error) {
    console.error("Error fetching data for deals:", error);
    setLoading(false);
  }
};

export const userBillingUnpaid = async ({ setLoading }) => {
  try {
    setLoading(true);
    const api_url = `${USER_BILLING_DATA}?status[]=unpaid&payment_method[]=quickbooks`;

    const response = await fetchWithHeaders(api_url, {
      method: "GET",
    });

    const result = await response.json();
    return result;
  } catch (error) {
    setLoading(false);
  }
};

export const updateStatus = async (id, updatedStatus) => {
  try {
    const response = await fetchWithHeaders(`${ORDER_BASE_URL}/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        status: updatedStatus,
      }),
    });
    if (response?.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES?.ERROR_AUTH + error.message);
  }
};

export const approveOrder = async (comment, id) => {
  try {
    const response = await fetchWithHeaders(APPROVE_ORDER, {
      method: "POST",
      body: JSON.stringify({
        comment: comment,
        order_id: id,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const reviseOrder = async (comment, id) => {
  try {
    const response = await fetchWithHeaders(REVISE_ORDER, {
      method: "POST",
      body: JSON.stringify({
        order_id: id,
        comment: comment,
      }),
    });
    if (response.ok) {
      console.log("Revise Orde", response);
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const uploadImage = async (formData) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await axios.post(UPLOAD_IMAGE, formData, {
      headers,
    });

    if (!response) {
      throw new Error("Failed to upload file");
    }

    return response;
  } catch (error) {
    console.log("errr: ", error);
  }
};

export const updateProfile = async (data) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetchWithHeaders(`${USER_PROFILE}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    throw new Error(ERROR_MESSAGES?.ERROR_AUTH + error.message);
  }
};

export const updatePassword = async (Password, OTP, email) => {
  const response = await fetch(USER_RESET_PASSWORD, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      otp: OTP,
      password: Password,
    }),
  });

  return await response.json();
};

export const fetchUserProfile = async () => {
  try {
    const response = await fetchWithHeaders(USER_PROFILE, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    localStorage.setItem("payment_method", result?.payment_method);
    return result;
  } catch (error) {
    return error;
  }
};

export const allPaymentMethods = async () => {
  const token = localStorage.getItem("accessToken");

  try {
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetchWithHeaders(ALL_PAYMENT_METHODS, {
      method: "GET",
      headers,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const DeletePaymentMethod = async (id) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
  };
  console.log("PaymentDelete", id);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    // const {
    const response = await fetchWithHeaders(DELETE_PAYMENT_METHOD, {
      method: "POST",
      body: JSON.stringify({
        payment_method_id: id,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const SavePayments = async (payment_ref, order_id, amount) => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetchWithHeaders(SAVE_PAYMENTS, {
      method: "POST",
      body: JSON.stringify({
        stripe_payment_ref: payment_ref,
        order_id: order_id,
        amount: amount,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const UpdatePayments = async () => {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetchWithHeaders(UPDATE_PAYMENTS, {
      method: "POST",
      body: JSON.stringify({
        cancel_url: CANCEL_URL,
        success_url: SUCCESS_URL,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES?.CONFLICT + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

// export const fetchCheckoutSession()
// {
//   const axios = require('axios');
// let data = JSON.stringify({
//   "cancel_url": "http://localhost:3000",
//   "success_url": "http://localhost:3000"
// });

// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: 'http://wordwe-publi-vioat16wsm7r-1888067637.us-east-1.elb.amazonaws.com/v1/users/update_payment',
//   headers: {
//     'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUyYjIyZmQ0N2VkZTY4MmY2OGZhY2NmZTdjNGNmNWIxMWIxMmI1NGIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiU2l3YSBKb2huIiwicm9sZSI6InVzZXIiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd29yZHdlbGwtMWVkYTMiLCJhdWQiOiJ3b3Jkd2VsbC0xZWRhMyIsImF1dGhfdGltZSI6MTcxNTcwMTUzNCwidXNlcl9pZCI6InlYeUpZSVJpRWVSU1RaR2FRbUlOY05DQWVjaDIiLCJzdWIiOiJ5WHlKWUlSaUVlUlNUWkdhUW1JTmNOQ0FlY2gyIiwiaWF0IjoxNzE1NzAxNTM0LCJleHAiOjE3MTU3MDUxMzQsImVtYWlsIjoic2V2aXdhMzk1N0ByZW5jci5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsic2V2aXdhMzk1N0ByZW5jci5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.X71RfW4BylhzKmFL1KuwkzpQ2kmr4NEbmOWpIlB4FDqWCYsOdDPTbhyaXVxTydS1wvamWa3T8RmhCb3e0g40D_5Qzrnf0P5AWcSEDLUuhYHIsGt5VceYzMBUw0_X4ZSa0XtW-sMpl6v03jFFVEOL36ya4j7gp4wC0YI--N_g1ImGDmwur_HxqV-0OSgrE3FyuYdHzCltWECZsDNGSZgp2Yt725O88jnlkSVodoKoKfGgECCVxK094U2gNhNR9lM9YnvJQKbP1X7yRVTKe0VmcK1I5bZa2VK0kUsWxMD6wNjoGwceTo8LTFngm_d-ImnV_CVS_HyfHWaAzxmJj-oRYw',
//     'Content-Type': 'application/json'
//   },
//   data : data
// };

// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
// })
// .catch((error) => {
//   console.log(error);
// });

// }
