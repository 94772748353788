import {
  REACT_APP_API_URL,
  REACT_APP_FIREBASE_KEY,
  REACT_APP_URL,
} from "../config/config";

const firebaseKey = REACT_APP_FIREBASE_KEY;

export const API_URL_SIGN_IN = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${firebaseKey}`;
export const ERROR_MESSAGES = {
  AUTH_FAILED: "Authentication failed:",
  ERROR_AUTH: "Error during authentication:",
  CONFLICT: "User already exist",
};
export const RENEW_TOKEN = `https:securetoken.googleapis.com/v1/token?key=${firebaseKey}`;
export const USER_SIGN_IN = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${firebaseKey}`;
const api_url = REACT_APP_API_URL;
const version = "/v1";

//payment
export const web_url = REACT_APP_URL;

//Admin
export const BASE_URL = `${api_url}${version}/admin/users`;
export const DELETE_DATA = `${BASE_URL}`;
export const USER_MANAGEMENT_URL = `${BASE_URL}/invite`;
export const GET_STAUTS_ENDPOINT = `${BASE_URL}/list?status=`;
export const GET_USERS = `${BASE_URL}/list`;

//Users
export const USER_BASE_URL = `${api_url}${version}/users`;
export const USER_RESET_PASSWORD = `${USER_BASE_URL}/verifyUpdatePassword`;
export const USER_FORGOT_PASSWORD = `${USER_BASE_URL}/forgotPasswordCode`;
export const USER_PROFILE = `${USER_BASE_URL}/profile`;
export const UPLOAD_IMAGE = `${USER_BASE_URL}/upload/image`;
export const SIGN_UP = `${api_url}${version}/users/signup`;

//Orders
export const ORDER_BASE_URL = `${api_url}${version}/orders`;
export const ORDER_USER = `${ORDER_BASE_URL}/mine`;
export const ORDER_ADMIN = `${ORDER_BASE_URL}/list/all`;
export const SAVE_FOR_REVIEW = `${ORDER_BASE_URL}/review`;
export const APPROVE_ORDER = `${ORDER_BASE_URL}/approve`;
export const REVISE_ORDER = `${ORDER_BASE_URL}/revise`;
export const UPLOAD_DOCUMENT = `${ORDER_BASE_URL}/upload/document`;

//Payments
export const PAYMENT_BASE_URL = `${api_url}${version}/payments`;
export const BILLING_DATA = `${PAYMENT_BASE_URL}/list`;
export const PAYMENT_INTENT = `${PAYMENT_BASE_URL}/intent`;
export const CREATE_INVOICE = `${PAYMENT_BASE_URL}/quickbooks`;
export const CREATE_PAYMENT = `${PAYMENT_BASE_URL}/stripe`;
export const USER_BILLING_DATA = `${PAYMENT_BASE_URL}/mine`;
export const ATTACH_PAYMENTS = `${USER_BASE_URL}/attatch/paymentmethod`;
export const START_PAYMENTS = `${USER_BASE_URL}/stripe/pay`;
export const ALL_PAYMENT_METHODS = `${USER_BASE_URL}/list/paymentmethod`;
export const DELETE_PAYMENT_METHOD = `${USER_BASE_URL}/detach/paymentmethod`;
export const SAVE_PAYMENTS = `${api_url}${version}/payments/stripe`;
export const UPDATE_PAYMENTS = `${USER_BASE_URL}/update_payment`;
export const CANCEL_URL = `${web_url}/user/setting`;
export const SUCCESS_URL = `${web_url}/user/setting`;
