import { useState, useEffect } from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "./CreateOrderForm.scss";
import CustomSelect from "../../Atoms/CustomSelect/CustomSelect";
import { OrderFormSchema, defaultValues } from "./OrderFormSchema";
import {
  CreateOrderOptions,
  customStylesCreateOrder,
} from "../../../Helpers/FilterOptions";
import InputField from "../../Atoms/InputField/InputField";
import Button from "../../Atoms/Button/Button";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import { useNavigate } from "react-router-dom";
import Border from "../../Atoms/Border";
import Image from "../../Atoms/Image";
import GreenExc from "../../../Assets/GreenExc.svg";
import { Text } from "../../Atoms/Typography/Text";

const CreateOrderForm = ({ handleNextStep, setData, initialData }) => {
  const [selectedType, setSelectedType] = useState(null);
  const handleSelectChange = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  const [ErrorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(OrderFormSchema),
  });

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.CREATE_NEW_ORDER_FORM;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;
  const textAreaClass = ErrorMessage
    ? `${STYLES?.TEXTAREA_CREATE_NEW_ORDER} ${STYLES?.TEXTAREA_CREATE_ERROR}`
    : STYLES?.TEXTAREA_CREATE_NEW_ORDER;
  const onSubmit = (data) => {
    setData({
      page_type: data?.SelectOption,
      PageBlogName: data?.PageBlogName,
      business_name: data?.BusinessName,
      website_url: data?.WebsiteURL,
      existing_url: data?.ExistingUrl,
      content_requirements: data?.ContentRequirements,
      content_examples: data?.ContentExamples,
      keywords: data?.Keywords,
      additional_notes: data?.[CONSTANTS?.ADDITIONAL_NOTES],
    });

    handleNextStep(data);
  };

  useEffect(() => {
    if (initialData) {
      Object.entries(initialData).forEach(([key, value]) => {
        setValue(key, value);
      });

      if (initialData[CONSTANTS.CONTENTEXAMPLE]) {
        setValue(
          CONSTANTS.CONTENTEXAMPLE,
          initialData[CONSTANTS.CONTENTEXAMPLE]
        );
      }
    }
  }, [initialData, setValue]);
  const HandleCancel = () => {
    navigate(ROUTES?.ORDER);
  };
  return (
    <Container className={STYLES?.ORDER_FORM_CONTAINER}>
      <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
        <Container className="width-40">
          <Controller
            name="SelectOption"
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={CONSTANTS?.PAGE_TYPE}
                options={CreateOrderOptions}
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                  handleSelectChange(selectedOption);
                }}
                onBlur={onBlur}
                value={value}
                ref={ref}
                SelectOption={value}
                setErrorMessage={setErrorMessage}
                errors={errors}
                styles={customStylesCreateOrder(errors)}
                isRequired
              />
            )}
          />

          <RowSpace margin={20} />
          <InputField
            name={CONSTANTS?.PAGE_BLOG_NAME}
            type={CONSTANTS?.TEXT}
            label={CONSTANTS?.PAGE_BLOG_NAME_POST}
            register={register}
            maxLength={50}
            field={CONSTANTS?.PAGE_BLOG_NAME}
            errors={errors}
            setErrorMessage={setErrorMessage}
            className={inputFieldClass}
            isRequired={true}
          />
          <RowSpace margin={20} />
          <InputField
            label={CONSTANTS?.BUSINESS_NAME}
            name={CONSTANTS?.BUSINESS_NAME_FROM}
            type={CONSTANTS?.TEXT}
            field={CONSTANTS?.BUSINESS_NAME_FROM}
            maxLength={50}
            register={register}
            setErrorMessage={setErrorMessage}
            errors={errors}
            className={inputFieldClass}
            isRequired
          />
          <RowSpace margin={20} />
          <InputField
            label={CONSTANTS?.WEBSITE_URL}
            name={CONSTANTS?.WEBSITE_URL_FORM}
            type={CONSTANTS?.TEXT}
            field={CONSTANTS?.WEBSITE_URL_FORM}
            register={register}
            setErrorMessage={setErrorMessage}
            errors={errors}
            className={inputFieldClass}
            isRequired
          />
          <RowSpace margin={20} />
          <InputField
            label={`${CONSTANTS?.IF_REWRITE_PROVIDE_LINK_TO_EXISTING_PAGE} ${CONSTANTS?.OPTIONAL}`}
            name={CONSTANTS?.EXISTING_URL}
            type={CONSTANTS?.TEXT}
            field={CONSTANTS?.EXISTING_URL}
            register={register}
            setErrorMessage={setErrorMessage}
            errors={errors}
            className={inputFieldClass}
          />
          <RowSpace margin={20} />

          <label htmlFor={CONSTANTS?.TEXTAREA}>
            {CONSTANTS?.CONTENT_REQUIREMENTS}
            <span className={STYLES?.AESTERISK}> *</span>
          </label>

          <Controller
            name={CONSTANTS?.CONTENT_REQUIREMENTS_FORM}
            control={control}
            defaultValue={defaultValues[CONSTANTS?.CONTENT_REQUIREMENTS_FORM]}
            setErrorMessage={setErrorMessage}
            errors={errors}
            render={({ field }) => (
              <textarea
                {...field}
                className={`${textAreaClass} ${
                  errors?.ContentRequirements
                    ? STYLES?.TEXTAREA_CREATE_ERROR
                    : ""
                }`}
              />
            )}
          />
          {errors?.ContentRequirements && (
            <p className={STYLES?.ERROR_MESSAGE}>
              {errors.ContentRequirements.message}
            </p>
          )}
          <RowSpace margin={20} />

          <label htmlFor={CONSTANTS?.TEXTAREA}>
            {`${CONSTANTS?.CONTENT_EXAMPLE} ${CONSTANTS?.OPTIONAL}`}
          </label>
          <Controller
            name={CONSTANTS?.CONTENT_EXAMPLES_FORM}
            control={control}
            defaultValue={defaultValues[CONSTANTS?.CONTENT_EXAMPLES_FORM]}
            setErrorMessage={setErrorMessage}
            errors={errors}
            render={({ field }) => (
              <textarea
                {...field}
                className={`${textAreaClass} ${
                  errors?.ContentExamples ? STYLES?.TEXTAREA_CREATE_ERROR : ""
                }`}
              />
            )}
          />
          {errors?.ContentExamples && (
            <p className={STYLES?.ERROR_MESSAGE}>
              {errors.ContentExamples.message}
            </p>
          )}

          <RowSpace margin={20} />
          <label htmlFor={CONSTANTS?.TEXTAREA}>
            {CONSTANTS?.KEYWORDS}
            <span className={STYLES?.AESTERISK}> *</span>
          </label>

          <Controller
            name={CONSTANTS?.KEYWORDS}
            control={control}
            defaultValue={defaultValues[CONSTANTS?.KEYWORDS]}
            render={({ field }) => (
              <textarea
                {...field}
                className={`${textAreaClass} ${
                  errors?.Keywords ? STYLES?.TEXTAREA_CREATE_ERROR : ""
                }`}
              />
            )}
          />
          {errors?.Keywords && (
            <p className={STYLES?.ERROR_MESSAGE}>{errors.Keywords.message}</p>
          )}

          <RowSpace margin={20} />
          <label htmlFor={CONSTANTS?.TEXTAREA}>
            {`${CONSTANTS?.ADDITIONAL_NOTES} ${CONSTANTS?.OPTIONAL}`}
          </label>
          <Controller
            name={CONSTANTS?.ADDITIONAL_NOTES}
            control={control}
            defaultValue={defaultValues[CONSTANTS?.ADDITIONAL_NOTES]}
            render={({ field }) => (
              <textarea
                {...field}
                className={STYLES?.TEXTAREA_CREATE_NEW_ORDER}
              />
            )}
          />
        </Container>
        <RowSpace margin={48} />

        <Border />

        <Container className="width-40"></Container>
        <Container className={STYLES?.CREATE_ORDER_FORM_BUTTON}>
          <Button
            type={CONSTANTS?.BUTTON_TYPE}
            className={STYLES?.SEND_BUTTON}
            buttonText={CONSTANTS?.NEXT}
          />
          <Button
            type="button"
            className={STYLES?.CANCEL_BUTTON}
            buttonText={CONSTANTS?.CANCEL}
            onClick={HandleCancel}
          />
        </Container>
      </form>
      {selectedType?.value && (
        <Container className={STYLES?.LIGHT_GREY_BOX}>
          <Container className={STYLES?.LIGHT_GREY_BOX_HEADING}>
            <Container className={STYLES?.SIMPLE_GREY_TEXT}>
              <Text>{CONSTANTS?.IT_WILL_COST}</Text>
            </Container>

            <Image src={GreenExc} className={STYLES?.SMALL_ICON} />
          </Container>

          <Text className={STYLES?.BOLD_GREEN_TEXT}>{`${"$"}${
            selectedType?.value
          }${".00"}`}</Text>
        </Container>
      )}

      {ErrorMessage && (
        <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
      )}
    </Container>
  );
};

export default CreateOrderForm;
