import React, { useContext, useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "./PaymentMethod.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreateNewOrder,
  allPaymentMethods,
  attachPayment,
  startPayment,
  stripePayment,
  SavePayments,
  DeletePaymentMethod,
} from "../../../Helpers/Functions";
import { defaultValues, schema } from "./FormSchema";
import { useNavigate } from "react-router-dom";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { errorToast, successToast } from "../../../Helpers/useToast";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import Container from "../../Atoms/Container/Container";
import { Text } from "../../Atoms/Typography/Text";
import SavedPayment from "../../Molecules/SavedPayment/SavedPayment";
import InputField from "../../Atoms/InputField/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AppContext from "../../../Context/AppContext";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentMethod = ({ Data }) => {
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    stripePayment(Data?.page_type?.value * 100).then((res) => {
      setClientSecret(res.client_secret);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appearance = {
    rules: {
      ".Tab": {
        boxShadow: "none",
      },

      ".TabIcon--selected": {
        fill: "#059669",
      },

      ".TabLabel--selected": {
        color: "#059669",
      },

      ".Tab--selected": {
        border: "1px solid #059669",
        color: "#059669",
        boxShadow: "none",
      },

      ".Tab--selected:focus": {
        border: "1px solid #059669",
        boxShadow: "none",
        transition: "none",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} data={Data} />
        </Elements>
      )}
    </>
  );
};

function CheckoutForm({ data }) {
  const navigate = useNavigate();
  const {
    page_type,
    business_name,
    website_url,
    existing_url,
    content_examples,
    keywords,
    content_requirements,
    additional_notes,
  } = data;
  const amount = page_type.value;
  const pagetype = page_type.label;
  const stripe = useStripe();
  const elements = useElements();
  const { isLoading, setIsLoading } = useContext(AppContext);
  const [paymentMethods, setPaymentMethods] = useState({});
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [zipCodeError, setZipCodeError] = useState("");
  const {
    register,

    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const message = null;
  setIsLoading(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  useEffect(() => {
    if (!elements) return;
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      cardElement.on("change", function (event) {
        const zip = event.value?.postalCode;
        if (zip && zip?.length > 5) {
          setZipCodeError(
            <span className={STYLES?.ERROR_MESSAGE}>
              Zip code cannot exceed 5 characters
            </span>
          );
        } else {
          setZipCodeError("");
        }
      });
    }
  }, [elements]);

  const handleSubmit = async (event, cardDataId) => {
    event.preventDefault();
    setOrderPlaced(true);
    if (!stripe || !elements) {
      setOrderPlaced(false);
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    setIsLoading(true);

    if (error) {
      console.log(error.message);
      errorToast(error.message);
      setIsLoading(false);
      setOrderPlaced(false);
    } else {
      const attachPaymentRes = await attachPayment(paymentMethod?.id);

      if (attachPaymentRes) {
        try {
          const makePaymentRes = await startPayment(
            cardDataId || paymentMethod?.id,
            data?.page_type?.value
          );

          const responseData = await CreateNewOrder({
            page_type: pagetype,
            price: amount,
            business_name: business_name,
            website_url: website_url,
            existing_url: existing_url,
            content_examples: content_examples,
            content_requirements: content_requirements,
            keywords: keywords,
            additional_notes: additional_notes,
          });
          const savePayment = await SavePayments(
            makePaymentRes.id,
            responseData.id,
            parseInt(amount)
          );
          if (savePayment) {
            navigate(ROUTES?.ORDER);
            successToast("New order submitted successfully");
          } else {
            setIsLoading(false);
            setOrderPlaced(false);

            errorToast("An unexpected error occurred.");
          }
        } catch (err) {
          console.error("Error during payment process:", err);
          errorToast(CONSTANTS?.INVALID_CARD_NUMBER);
          setIsLoading(false);
          setOrderPlaced(false);
          await DeletePaymentMethod(paymentMethod?.id);
        }
      } else {
        errorToast("An unexpected error occurred.");
      }
      setIsLoading(false);
      setOrderPlaced(false);
    }
  };

  const cardElementStyle = {
    style: {
      base: {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "17px",
        borderRadius: "4px",

        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000000",
        },
        "::placeholder": {
          color: "gray",
          placeholder: "00000000",
        },
      },
    },
  };

  const handleCardClick = async (cardData) => {
    setOrderPlaced(true);
    if (!stripe || !elements) {
      setOrderPlaced(false);
      return;
    }
    const makePaymentRes = await startPayment(
      cardData?.id,
      data?.page_type?.value
    );
    const responseData = await CreateNewOrder({
      page_type: pagetype,
      price: amount,
      business_name: business_name,
      website_url: website_url,
      existing_url: existing_url,
      content_examples: content_examples,
      content_requirements: content_requirements,
      keywords: keywords,
      additional_notes: additional_notes,
    });
    const savePayment = await SavePayments(
      makePaymentRes.id,
      responseData.id,
      parseInt(amount)
    );
    if (savePayment) {
      setIsLoading(false);
      navigate(ROUTES?.ORDER);

      successToast("New order submitted successfully");
    } else {
      setIsLoading(false);
      setOrderPlaced(false);
      errorToast("An unexpected error occurred.");
    }
    setOrderPlaced(false);
    setIsLoading(false);

  };

  useEffect(() => {
    allPaymentMethods().then((response) => {
      setPaymentMethods(response);
    });
  }, []);
  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <form id="payment-form" onSubmit={handleSubmit}>
          <Container className={STYLES?.PAYEMNT_DETAILS}>
            <Container className={STYLES?.PRICE_TAG}>
              <Text className={STYLES?.PRICE_TAG_TEXT}>{CONSTANTS?.PRICE}</Text>
              <Text className={STYLES?.PRICE_TAG_GREEN_TEXT}>{`${"$"}${
                data?.page_type?.value
              }${".00"}`}</Text>
            </Container>
          </Container>
          <RowSpace margin={20} />
          <RowSpace margin={20} />
          {paymentMethods?.data?.length > 0 ? (
            <SavedPayment
              paymentMethods={paymentMethods?.data}
              clickable={!orderPlaced}
              onCardClick={handleCardClick}
              deleteText={""}
            />
          ) : (
            <>
              <InputField
                name={CONSTANTS?.NAME}
                type={CONSTANTS?.TEXT}
                field={CONSTANTS?.NAME}
                placeholder={""}
                register={register}
                errors={errors}
                label={CONSTANTS?.NAME_ON_THE_CARD}
                className={STYLES?.PAYMENT_INPUT_FIELD}
              />
              <RowSpace margin={40} />
              <label>{CONSTANTS?.CARD_DETAILS}</label>
              <RowSpace margin={20} />
              <Container className={STYLES?.CUSTOM_CARD_INPUT_FIELD}>
                <CardElement options={cardElementStyle} />
              </Container>
              {zipCodeError && <div id="card-errors">{zipCodeError}</div>}
              <RowSpace margin={60} />

              <button
                disabled={!stripe || !elements || orderPlaced}
                id="submit"
              >
                <span id="button-text">Pay now</span>
              </button>
            </>
          )}
          {message && <div id="payment-message">{message}</div>}
        </form>
      )}
    </>
  );
}
export default PaymentMethod;
