import React, { useState } from "react";
import { Text } from "../../Atoms/Typography/Text";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import Button from "../../Atoms/Button";
import "./Review.scss";
import { CreateNewOrder } from "../../../Helpers/Functions";
import { useNavigate } from "react-router-dom";
import { successToast } from "../../../Helpers/useToast";
const Review = ({ Data, handleNextStep, handleCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user_payment_method = localStorage.getItem("payment_method");
  const navigate = useNavigate();

  const {
    existing_url,
    page_type,
    business_name,
    website_url,
    content_requirements,
    content_examples,
    keywords,
    additional_notes,
  } = Data;

  const keywordsArray = keywords.split(/[,\s]+/);
  const handleFormSumission = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const {
      page_type,
      business_name,
      existing_url,
      website_url,
      content_examples,
      content_requirements,
      additional_notes,
    } = Data;

    const payment_method = page_type.value;
    const pagetype = page_type.label;
    const user_payment_method = localStorage.getItem("payment_method");
    try {
      if (user_payment_method === "quickbooks") {
        const responseData = await CreateNewOrder({
          page_type: pagetype,
          price: payment_method,
          business_name: business_name,
          website_url: website_url,
          existing_url: existing_url,
          content_examples: content_examples,
          content_requirements: content_requirements,
          keywords: keywordsArray,
          additional_notes: additional_notes,
        });
        localStorage.setItem("order_id", responseData?.id);
        successToast("New order submitted successfully");
        navigate(ROUTES?.ORDER);
      } else {
        handleNextStep();
      }
    } catch (error) {
      console.log(error, "errrrr");
    }
  };
  return (
    <Container className={STYLES?.REVIEW_CONTAINER}>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.PAGE_TYPE}
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{page_type?.label}</Text>
      </Container>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          Price
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{`${"$"}${
          page_type?.value
        }${".00"}`}</Text>
      </Container>

      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.BUSINESS_NAME}
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{business_name}</Text>
      </Container>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.WEBSITE_URL}
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{website_url}</Text>
      </Container>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.IF_REWRITE_PROVIDE_LINK_TO_EXISTING_PAGE}
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{existing_url}</Text>
      </Container>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.CONTENT_REQUIREMENTS}
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{content_requirements}</Text>
      </Container>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.CONTENT_EXAMPLE}
        </Text>

        <Text className={STYLES?.ACCORDION_DATA}>{content_examples}</Text>
      </Container>
      {/* <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.CONTENT_EXAMPLE}
        </Text>
        {content_examples?.map(({ url }) => (
          <Text className={STYLES?.ACCORDION_DATA}>{url}</Text>
        ))}
      </Container> */}
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.KEYWORDS}
        </Text>

        {keywordsArray?.map((text, index) => (
          <Text key={index} className={STYLES?.ACCORDION_DATA}>
            {text}
          </Text>
        ))}
      </Container>
      <Container className={STYLES?.ORDER_REVIEW_TEXT}>
        <Text variant="p" className={STYLES?.ACCORDION_DATA_TITLE}>
          {CONSTANTS?.ADDITIONAL_NOTES}
        </Text>
        <Text className={STYLES?.ACCORDION_DATA}>{additional_notes}</Text>
      </Container>
      <Container className={STYLES?.REVIEW_BUTTON}>
        <Button
          type="button"
          className={STYLES?.SUBMIT_BUTTON}
          buttonText={
            user_payment_method === "stripe"
              ? CONSTANTS?.NEXT
              : CONSTANTS?.SUBMIT
          }
          onClick={handleFormSumission}
          disabled={isSubmitting}
        />
        <Button
          type="button"
          className={STYLES?.CANCEL_BUTTON}
          buttonText={CONSTANTS?.CANCEL}
          onClick={handleCancel}
        />
      </Container>
    </Container>
  );
};

export default Review;
